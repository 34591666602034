import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { APPLICATION_SUBDOMAIN } from '../../constants';
import { APP_TAB_KEY_OFFICE } from '../../router/constants';
import { secondaryColor } from '../../theme';

interface CurrentStudioNameProps {
    studioName: string;
    studioDomain: string;
}

const CurrentStudioNameContainer = styled.div`
    font-size: medium;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.4px;
    color: ${secondaryColor};
    padding: 0;
    display: flex;
    margin: 0 15px 0 0;
    line-height: 0;

    @media (max-width: 767px) {
        display: none;
    }
`;

export const CurrentStudioName = ({ studioName, studioDomain }: CurrentStudioNameProps) => {
    return (
        <CurrentStudioNameContainer>
            <Link
                to={{
                    pathname: `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${studioDomain}`,
                }}
            >
                <div className="current-studio-name">{studioName}</div>
            </Link>
        </CurrentStudioNameContainer>
    );
};
