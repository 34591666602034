import { CreditCardOutlined } from '@ant-design/icons';
import { useReactiveVar } from '@apollo/client';
import { paymentStatus as paymentStatusType, tariffStatus as tariffStatusType } from '@fyooga/codebook';
import { differenceInDays, parseISO } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TariffSubscriptionInfo } from '../../components/ui/TariffSubscriptionInfo';
import { APPLICATION_SUBDOMAIN } from '../../constants';
import { useGetOrganisationOfficeLicenseData } from '../../hooks/useGetOrganisationOfficeLicenseData';
import { employeeCurrentHandledOfficeVar } from '../../lib/apollo/cache';
import { useFormatters } from '../../lib/formatters';
import {
    APP_TAB_KEY_OFFICE,
    SETTINGS_TAB_KEY_SUBSCRIPTION_ACTIVATION,
    SETTINGS_TAB_KEY_SUBSCRIPTION_OVERVIEW,
    SETTINGS_TAB_KEY_SUBSCRIPTION_PAYMENT,
} from '../../router/constants';
import { isTariffFree, isTariffTrial } from '../../util/tariff';
import { LicenceOrderHeaderInfo } from './userMenu/LicenceOrderHeaderInfo';

interface ExpiredSubscriptionOverviewContentProps {
    onSetSubscriptionInfoText: (text: string | null, domain: string | null) => void;
    canUserWriteOrgTariffInfo: boolean;
    officeId: number;
    organisationId: number;
}

export const ExpiredSubscriptionOverviewContent = ({
    onSetSubscriptionInfoText,
    canUserWriteOrgTariffInfo,
    officeId,
    organisationId,
}: ExpiredSubscriptionOverviewContentProps) => {
    const { t } = useTranslation();
    const { SimpleDateTimeFormatter } = useFormatters();
    const employeeCurrentHandledOfficeData = useReactiveVar(employeeCurrentHandledOfficeVar);

    const organisationLicense = useGetOrganisationOfficeLicenseData(organisationId, officeId);
    const { data: organisationLicenseData, loading: loadingOrganisationLicenseData } = organisationLicense;

    const getExpiredSubscriptionOverview = useMemo(() => {
        if (employeeCurrentHandledOfficeData && !loadingOrganisationLicenseData) {
            const { organisationTariff, domain, officeId, organisationId } = employeeCurrentHandledOfficeData;
            const currentOrganisationLicensePayment =
                organisationLicenseData?.currentOrganisationLicensePayment ?? null;

            // It means not completed onboarding
            if (!organisationTariff) {
                return null;
            }

            const { name: tariffName, tariffStatus, validTo } = organisationTariff;
            const isTrial = isTariffTrial(tariffName);
            const isFreeTariff = isTariffFree(tariffName);

            if (tariffStatus === tariffStatusType.CANCEL_AT_PERIOD_END && canUserWriteOrgTariffInfo) {
                return (
                    <TariffSubscriptionInfo
                        extendButtonText={t('subscriptionNs:buyLicenseAgain')}
                        officeId={officeId}
                        domain={domain}
                        linkTo={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${domain}/${SETTINGS_TAB_KEY_SUBSCRIPTION_ACTIVATION}`}
                    />
                );
            }

            if (tariffStatus === tariffStatusType.WAITING_FOR_PAYMENT) {
                return (
                    <LicenceOrderHeaderInfo
                        domain={domain}
                        organisationId={organisationId}
                        officeId={officeId}
                        onSetSubscriptionInfoText={onSetSubscriptionInfoText}
                    />
                );
            }

            const daysRemaining = validTo ? differenceInDays(parseISO(validTo), new Date()) : null;
            // @ts-ignore
            const daysRemainingTranslation = t('plurals.dayNominative', {
                postProcess: 'interval',
                count: daysRemaining,
            });

            // do not show if it is for extended lecturers
            if (isTrial && daysRemaining && daysRemaining < 31) {
                const isCurrentLicensePaymentWaitingForPayment =
                    currentOrganisationLicensePayment &&
                    currentOrganisationLicensePayment.paymentStatus === paymentStatusType.WAITING_FOR_PAYMENT;
                // if some stripe zenamu license order has been created
                const linkTo = isCurrentLicensePaymentWaitingForPayment
                    ? `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${domain}/${SETTINGS_TAB_KEY_SUBSCRIPTION_PAYMENT}/${currentOrganisationLicensePayment.id}`
                    : `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${domain}/${SETTINGS_TAB_KEY_SUBSCRIPTION_ACTIVATION}`;

                return (
                    <div>
                        <TariffSubscriptionInfo
                            extendButtonText={
                                isCurrentLicensePaymentWaitingForPayment
                                    ? t('subscriptionNs:waitingForPaymentGotoOverview')
                                    : t('subscriptionNs:buyLicense')
                            }
                            officeId={officeId}
                            domain={domain}
                            linkTo={linkTo}
                        />
                    </div>
                );
            }

            if (isFreeTariff) {
                const isCurrentLicensePaymentWaitingForPayment =
                    currentOrganisationLicensePayment &&
                    currentOrganisationLicensePayment.paymentStatus === paymentStatusType.WAITING_FOR_PAYMENT;

                return (
                    <TariffSubscriptionInfo
                        extendButtonText={
                            isCurrentLicensePaymentWaitingForPayment
                                ? t('subscriptionNs:waitingForPaymentGotoOverview')
                                : t('subscriptionNs:buyLicense')
                        }
                        officeId={officeId}
                        domain={domain}
                        isFreeTariff={isFreeTariff}
                        linkTo={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${domain}/${SETTINGS_TAB_KEY_SUBSCRIPTION_ACTIVATION}`}
                    />
                );
            }

            return (
                <TariffSubscriptionInfo
                    extendButtonText={t('sidebar.subscription')}
                    officeId={officeId}
                    isFreeTariff={isFreeTariff}
                    linkTo={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${domain}/${SETTINGS_TAB_KEY_SUBSCRIPTION_OVERVIEW}`}
                    icon={<CreditCardOutlined />}
                />
            );
        }

        return null;
    }, [canUserWriteOrgTariffInfo, t, employeeCurrentHandledOfficeData, loadingOrganisationLicenseData]);

    useEffect(() => {
        if (employeeCurrentHandledOfficeData && !loadingOrganisationLicenseData) {
            const { organisationTariff, domain } = employeeCurrentHandledOfficeData;

            if (organisationTariff) {
                const { name: tariffName, tariffStatus, validTo } = organisationTariff;

                const isTrial = isTariffTrial(tariffName);
                const isFreeTariff = isTariffFree(tariffName);

                if (tariffStatus === tariffStatusType.CANCEL_AT_PERIOD_END && canUserWriteOrgTariffInfo) {
                    onSetSubscriptionInfoText(
                        t('subscriptionNs:licenseCancelAtPeriodEnd', {
                            validTo: SimpleDateTimeFormatter.format(parseISO(validTo)),
                        }),

                        domain,
                    );
                } else if (isTrial) {
                    const daysRemaining = validTo ? differenceInDays(parseISO(validTo), new Date()) : null;

                    const daysRemainingTranslation = t('plurals.dayNominative', {
                        postProcess: 'interval',

                        count: daysRemaining,
                    });

                    if (daysRemaining && daysRemaining < 31) {
                        onSetSubscriptionInfoText(
                            `${t('subscriptionNs:trialRemainingDays', {
                                remainingDays: daysRemaining,

                                daysPlural: daysRemainingTranslation,
                            })}`,

                            domain,
                        );
                    } else {
                        onSetSubscriptionInfoText(null, null);
                    }
                }
                if (isFreeTariff) {
                    onSetSubscriptionInfoText(`${t('subscriptionNs:useWithoutLimits')}`, domain);
                } else {
                    onSetSubscriptionInfoText(null, null);
                }
            } else {
                onSetSubscriptionInfoText(null, null);
            }
        } else {
            onSetSubscriptionInfoText(null, null);
        }
    }, [employeeCurrentHandledOfficeData, loadingOrganisationLicenseData, canUserWriteOrgTariffInfo, t]);

    return <>{getExpiredSubscriptionOverview}</>;
};
