import { HomeOutlined } from '@ant-design/icons';
import { useQuery, useReactiveVar } from '@apollo/client';
import { fyoogaQueries } from '@fyooga/graphql-requests';
import { Drawer } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Logo } from '../../components/basics/Logo';
import { InteractiveButton } from '../../components/buttons';
import { DisabledLinkLikeAUnderline, LinkAUnderline } from '../../components/LinkAUnderline';
import { SwitchAdmin } from '../../components/SwitchAdmin';
import { CurrentStudioName } from '../../components/ui/CurrentStudioName';
import { APPLICATION_SUBDOMAIN } from '../../constants';
import history from '../../history';
import { useViewport } from '../../hooks/useViewport';
import {
    currentPublicViewStudioVar,
    employeeCurrentHandledOfficeVar,
    isNetworkErrorVar,
    userIdVar,
} from '../../lib/apollo/cache';
import { primaryColor } from '../../theme';
import { OfficeMenu } from '../menu';
import { ExpiredSubscriptionOverview } from '../menu/ExpiredSubscriptionOverview';
import { SidebarMobileMenu } from '../SidebarMobileMenu';
import { AuthorizedHeaderTariffLimits } from './AuthorizedHeaderTariffLimits';
import { HeaderMobileWrapper } from './HeaderMobileWrapper';

const MenuContainer = styled.div`
    display: none;

    @media (max-width: 767px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }
`;

export const AuthorizedHeader = ({ isSidebarVisible }: { isSidebarVisible: boolean }) => {
    const { t } = useTranslation();
    let location = useLocation();
    const { isMobileOrTablet, isDesktop } = useViewport();

    const currentPublicViewStudio = useReactiveVar<any>(currentPublicViewStudioVar);
    const employeeCurrentHandledOfficeData = useReactiveVar(employeeCurrentHandledOfficeVar);

    const userId = useReactiveVar(userIdVar);
    const isNetworkError = useReactiveVar(isNetworkErrorVar);
    const [mobileMenuDrawerVisible, setMobileMenuDrawerVisible] = useState(false);

    const { loading: loadingOfficeData, data: officeData } = useQuery(fyoogaQueries.getOfficeByDomain, {
        variables: {
            domain: employeeCurrentHandledOfficeData?.domain,
        },
        skip:
            typeof employeeCurrentHandledOfficeData?.domain === 'undefined' ||
            employeeCurrentHandledOfficeData?.domain === null,
    });

    const { data: userEmployersDropdownData } = useQuery(fyoogaQueries.getOfficeEmploymentsByUserIdForDropdown, {
        fetchPolicy: 'cache-and-network',
        variables: {
            userId,
        },
        skip: !userId,
    });

    const isLocationDashboardOverview =
        location?.pathname === '/app' || location?.pathname === '/app/' || location?.pathname === '/';

    const officeName = useMemo(() => {
        if (
            userEmployersDropdownData?.officeEmploymentsByUserIdForDropdown &&
            userEmployersDropdownData.officeEmploymentsByUserIdForDropdown.length
        ) {
            return isDesktop ? (
                <OfficeMenu
                    organisationsEmployeeList={userEmployersDropdownData.officeEmploymentsByUserIdForDropdown}
                />
            ) : null;
        } else if (currentPublicViewStudio?.name) {
            return (
                <>
                    <CurrentStudioName
                        studioName={currentPublicViewStudio.name}
                        studioDomain={currentPublicViewStudio.domain}
                    />
                </>
            );
        }
        return null;
    }, [userEmployersDropdownData, currentPublicViewStudio]);

    const showMobileMenuDrawer = () => {
        setMobileMenuDrawerVisible(true);
    };

    const closeMobileMenuDrawer = () => {
        setMobileMenuDrawerVisible(false);
    };

    return (
        <HeaderMobileWrapper isMobileOrTablet={isMobileOrTablet}>
            <div className="public-app-menu">
                {isSidebarVisible && (
                    <div>
                        <MenuContainer onClick={showMobileMenuDrawer}>
                            <svg
                                width={40}
                                height={40}
                                viewBox="0 0 34 34"
                                className="icon-menu"
                                aria-labelledby="title-menu"
                                role="img"
                                aria-hidden="true"
                                aria-label="Menu"
                            >
                                <title id="title-menu">Menu</title>
                                <g fill-rule="evenodd">
                                    <path
                                        style={{ fill: '#737373' }}
                                        d="M3 6.96a1 1 0 110-2h28a1 1 0 010 2H3zm0 6.07a1 1 0 110-2h28a1 1 0 010 2H3zm0 6a1 1 0 110-2h28a1 1 0 010 2H3z"
                                    />
                                    <path
                                        style={{ fill: '#737373' }}
                                        d="M4.43 30.77l.2-2.52c.06-.81.1-1.45.1-1.9l.05.17c.03.12.1.34.2.66.1.31.23.67.38 1.07l.91 2.45h.88l.92-2.45c.3-.79.53-1.42.7-1.9l.12 1.9.24 2.52h1.49l-.68-6.5H8.3l-.92 2.36c-.35.89-.57 1.55-.68 1.97l-.05-.19a9.64 9.64 0 00-.21-.7 8.89 8.89 0 00-.4-1.08L5.1 24.26H3.5l-.56 6.5h1.48zm12.08 0v-1.19h-2.55v-1.48h2.24v-1.18h-2.24v-1.48h2.51v-1.19h-4.01v6.51h4.05zm3.44 0v-2.7c0-.77-.05-1.44-.15-2.02l.08.24c.06.16.17.41.32.75.16.34.34.68.55 1.03l1.56 2.7h1.63v-6.51h-1.49v2.7c0 .84.03 1.5.1 1.98l-.1-.28a9.01 9.01 0 00-.28-.63c-.18-.35-.36-.71-.56-1.07l-1.52-2.7H18.46v6.51h1.49zm8.59.1c.81 0 1.46-.22 1.93-.67.48-.45.72-1.1.72-1.95V24.26h-1.49v4c0 .84-.39 1.26-1.16 1.26-.77 0-1.16-.4-1.16-1.2V24.26h-1.49v4.06c0 .81.24 1.44.73 1.88.49.44 1.12.66 1.92.66z"
                                    />
                                </g>
                            </svg>
                        </MenuContainer>
                        <Drawer
                            title={
                                userEmployersDropdownData?.officeEmploymentsByUserIdForDropdown &&
                                userEmployersDropdownData.officeEmploymentsByUserIdForDropdown.length && (
                                    <OfficeMenu
                                        organisationsEmployeeList={
                                            userEmployersDropdownData.officeEmploymentsByUserIdForDropdown
                                        }
                                    />
                                )
                            }
                            placement="left"
                            closable={false}
                            onClose={closeMobileMenuDrawer}
                            open={mobileMenuDrawerVisible}
                            width={'70%'}
                        >
                            <SidebarMobileMenu
                                mobile={true}
                                closeMobileMenuDrawer={closeMobileMenuDrawer}
                                office={officeData?.officeByDomain}
                            />
                        </Drawer>
                    </div>
                )}
                <div className="app-menu">
                    <div className="app-headline">
                        <div className="studio-logo">
                            <Logo width={30} color="#888bbb" />
                        </div>
                        {isDesktop ? (
                            <>
                                {isLocationDashboardOverview ? (
                                    <div style={{ padding: '0 15px 0 15px', fontWeight: 500 }}>
                                        <DisabledLinkLikeAUnderline>
                                            {t('headerMenu.atOverview')}
                                        </DisabledLinkLikeAUnderline>
                                    </div>
                                ) : (
                                    <InteractiveButton
                                        type="link"
                                        onClick={() => history.push(`/${APPLICATION_SUBDOMAIN}`)}
                                        value={t('headerMenu.goToOverview')}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                {isLocationDashboardOverview ? (
                                    <b>
                                        <DisabledLinkLikeAUnderline>
                                            {t('headerMenu.atOverview')}
                                        </DisabledLinkLikeAUnderline>
                                    </b>
                                ) : (
                                    <>
                                        <Link
                                            to={`/${APPLICATION_SUBDOMAIN}`}
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <HomeOutlined
                                                style={{
                                                    fontSize: '21.9px',
                                                    color: primaryColor,
                                                }}
                                            />
                                        </Link>
                                        {currentPublicViewStudio?.domain && currentPublicViewStudio?.officeId && (
                                            <SwitchAdmin
                                                domain={currentPublicViewStudio.domain}
                                                officeId={currentPublicViewStudio.officeId}
                                                withIcon={false}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {officeName}
                        {!loadingOfficeData && <AuthorizedHeaderTariffLimits office={officeData?.officeByDomain} />}
                    </div>
                    {!isNetworkError && <ExpiredSubscriptionOverview />}
                </div>
            </div>
        </HeaderMobileWrapper>
    );
};
